import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()

export const serverName = env.REACT_APP_HEROKU_APP_NAME
export const appVersion = env.REACT_APP_HEROKU_RELEASE_VERSION
export const branch = env.REACT_APP_HEROKU_BRANCH

const getAuthDomain = () => {
  if (serverName === 'actions-web-production') {
    return window.location.hostname
    // return 'actions.moleskinestudio.com'
  }
  if (serverName === 'actions-web-staging') {
    return window.location.hostname
    // return 'actions-staging.moleskinestudio.com'
  }
  // use firebase auth for local dev and other PR builds
  return env.REACT_APP_FIREBASE_AUTHDOMAIN
}

const authDomain = getAuthDomain()

export const firebase = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY,
  databaseURL: env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: env.REACT_APP_FIREBASE_APPID,
  authDomain
}

const getEnv = () => {
  if (serverName == null) {
    return 'development'
  } else if (serverName === 'actions-web-production') {
    return 'production'
  } else {
    return 'staging'
  }
}

export const environment = getEnv()

export const helpScoutKey = env.REACT_APP_HELPSCOUT_BEACON_KEY
export const sentryURL = env.REACT_APP_SENTRY_URL
export const FathomToken = env.REACT_APP_FATHOM
export const GoogleClientID = env.REACT_APP_GOOGLE_CLIENT_ID
