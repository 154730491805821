import React, { Component } from 'react'
import { addTags } from './Sentry'
import { showReportDialog, withScope, captureException } from '@sentry/browser'
import { connect } from 'react-redux'
import { Heading, Text as BaseText, Box } from 'rebass'
import { PrimaryButton } from '@bonobolabs/mds-web-ui-reach'
import { SideBySideLayout } from './components/ui/mds-web-ui/SideBySideLayout'

const handleChunkLoadError = async error => {
  if (!error?.name?.includes('ChunkLoadError')) {
    return
  }

  console.warn('ChunkLoadError detected, clearing cache and reloading...')

  try {
    const cacheNames = await caches.keys()

    if (cacheNames.length === 0) {
      console.warn('No caches found, skipping reload.')
      return
    }

    console.log(`Clearing ${cacheNames.length} cache(s)...`)
    await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)))

    // Mark reload attempt in session storage
    sessionStorage.setItem('chunkErrorReloaded', 'true')

    console.log('Cache cleared. Reloading page...')
    // window.location.reload()
  } catch (err) {
    console.error('Failed to clear cache:', err)
  }
}

const Text = props => (
  <BaseText
    mb={4}
    lineHeight={1.5}
    fontSize={[2, 3]}
    color="grayapptitle"
    {...props}
  />
)

export const ErrorPage = ({
  title = 'Sorry, something went wrong',
  children
}) => (
  <SideBySideLayout useRouterLink={false}>
    <Box css={{ textAlign: 'center' }}>
      <Heading mt={4} mb={4} color="grayapptitle" fontSize={[4, 5]}>
        {title}
      </Heading>
      {children ? (
        children
      ) : (
        <Text>
          Our team has been notified, we&apos;ll fix it as soon as we can!
        </Text>
      )}
      <PrimaryButton mt={4} is="a" href="/" uppercase>
        Go back and try again
      </PrimaryButton>
    </Box>
  </SideBySideLayout>
)

export const RequestErrorReport = ({ eventId, user }) => (
  <ErrorPage>
    {eventId ? (
      <>
        <Text css={{ maxWidth: '80%' }} mx="auto">
          We’ve been notified of the error but you can help us track it down
          quicker by giving us a bit more information.
        </Text>
        <div>
          <PrimaryButton
            bg="white"
            color="graylabeltext"
            borderColor="graybuttonborder"
            onClick={() => showReportDialog({ eventId, user })}
            children="Tell us what happened"
            uppercase
          />
        </div>
      </>
    ) : null}
  </ErrorPage>
)

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  addUser = scope => {
    const { auth } = this.props
    if (auth && !auth.isEmpty) {
      scope.setUser({
        id: auth.uid,
        username: auth.displayName,
        email: auth.email
      })
    }
  }

  getErrorReportUser = () => {
    const { auth } = this.props
    if (auth && !auth.isEmpty) {
      return {
        name: auth.displayName,
        email: auth.email
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    withScope(scope => {
      addTags(scope)
      this.addUser(scope)
      scope.setExtras(errorInfo)
      const eventId = captureException(error)
      this.setState({ eventId })
    })

    handleChunkLoadError(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <RequestErrorReport
          eventId={this.state.eventId}
          user={this.getErrorReportUser()}
        />
      )
    }

    return this.props.children
  }
}

export default connect(({ firebase: { auth } }) => ({ auth }))(ErrorBoundary)
