import React from 'react'
import { Heading, LoadingIcon } from '@bonobolabs/mds-web-ui-reach'
import { SideBySideLayout } from './SideBySideLayout'

export const LoadingPage = ({ ...props }) => (
  <SideBySideLayout {...props}>
    <Heading>Loading</Heading>
    <LoadingIcon />
  </SideBySideLayout>
)

export default LoadingPage
