import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Logo, LogoNormalLink } from './Logo'
import { Main, GrowBox, NoShrinkBox } from './Main'
import { Flex, Box } from 'rebass'
import { Footer } from './Footer'

/* Usage:

  <SideBySideLayout>
    // Main page content
  </SideBySideLayout>

*/

const Left = styled(Flex)`
  display: flex
  flex-direction: column
  align-items: flex-end

  @media screen and (max-width:640px) {
    align-items: center;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(220px, 50vw) minmax(600px, auto);
  height: 100vh;
  font-family: inherit;

  @media screen and (max-width: 820px) {
    grid-template-columns: 220px auto;
  }

  @media screen and (max-width: 640px) {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    height: min-content;
  }
`

const Content = ({ children, showFooter = false, currentSite, ...props }) => (
  <Main bg="white" {...props}>
    <GrowBox px={[3, 3, 4, 4, 5]} py={3} pt={[5, 6]} maxWidth={900}>
      {children}
    </GrowBox>
    {showFooter && (
      <NoShrinkBox>
        <Footer
          mt={4}
          px={[3, 3, 4, 4, 5]}
          py={[4, 5]}
          bg="footerlightblue"
          currentSite={currentSite}
        />
      </NoShrinkBox>
    )}
  </Main>
)

Content.propTypes = {
  children: PropTypes.node,
  showFooter: PropTypes.bool,
  currentSite: Footer.propTypes.currentSite
}

export const SideBySideLayout = props => {
  const { children, useRouterLink = true, showFooter = false, ...rest } = props

  return (
    <Container>
      <Left bg="sidebarblue">
        <Box pt={[4, '30vh']} px={[4, 4, 4, 5]} pb={4}>
          {useRouterLink ? <Logo /> : <LogoNormalLink />}
        </Box>
      </Left>
      <Content showFooter={showFooter} {...rest}>
        {children}
      </Content>
    </Container>
  )
}

SideBySideLayout.propTypes = {
  children: PropTypes.node,
  useRouterLink: PropTypes.bool,
  ...Content.propTypes
}
