import styled from 'styled-components'
import {
  space,
  width,
  maxWidth,
  fontSize,
  color,
  fontFamily
} from 'styled-system'
import { Box } from 'rebass'

export const Main = styled.main(
  {
    overflowY: 'auto',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    fontFamily: 'inherit',

    '@media screen and (max-width: 640px)': {
      overflowY: 'initial'
    }
  },
  space,
  width,
  maxWidth,
  fontSize,
  fontFamily,
  color
)

export const GrowBox = styled(Box)({
  fontFamily: 'inherit',
  flexGrow: 1
})

export const NoShrinkBox = styled.div({
  flexShrink: 0
})
