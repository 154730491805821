import React from 'react'
import { connect } from 'react-redux'
import { Heading, Text as BaseText, Box } from 'rebass'
import { PrimaryButton } from '@bonobolabs/mds-web-ui-reach'
import { useServiceWorker } from './serviceWorker'
import { SideBySideLayout } from './components/ui/mds-web-ui/SideBySideLayout'

const Text = props => (
  <BaseText
    mb={4}
    lineHeight={1.5}
    fontSize={[2, 3]}
    color="grayapptitle"
    {...props}
  />
)

const SchemaError = () => {
  const { assetsUpdateReady, updateAssets } = useServiceWorker()

  return (
    <SideBySideLayout useRouterLink={false}>
      <Box css={{ textAlign: 'center' }}>
        <Heading mt={4} mb={4} color="grayapptitle" fontSize={[4, 5]}>
          Incompatible app version
        </Heading>
        <Text>
          You have used a more recent version of Actions, and this version of
          Actions web is incompatible.&nbsp;
          {assetsUpdateReady ? (
            <div>
              <PrimaryButton
                mt={3}
                onClick={() => updateAssets()}
                children="Install update"
                uppercase
              />
            </div>
          ) : (
            'Please close and reopen your browser tab to update to the latest version of Actions web.'
          )}
        </Text>
        <Text fontSize={[1, 2]}>
          If you are using the Actions iOS beta, the latest version of Actions
          web may not be released yet - sorry!
        </Text>
      </Box>
    </SideBySideLayout>
  )
}

const SchemaErrorPageInner = ({ schemaVersionError, children }) =>
  schemaVersionError ? <SchemaError /> : children

const SchemaVersionErrorPage = connect(({ schemaVersionError }) => ({
  schemaVersionError
}))(SchemaErrorPageInner)

export default SchemaVersionErrorPage
