import React, { Component, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { Router, Redirect } from '@reach/router'
import { parseURL } from './utils/href'
import SchemaVersionErrorPage from './SchemaErrorPage'
import LoadingPage from './components/ui/mds-web-ui/LoadingPage'

const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ './components/Login')
)
const Logout = lazy(() =>
  import(/* webpackChunkName: "logout" */ './components/Logout')
)
const ActionsPage = lazy(() =>
  import(/* webpackChunkName: "actionspage" */ './components/ActionsPage')
)

class ActionsHomeInner extends Component {
  static propTypes = {
    auth: PropTypes.object,
    location: PropTypes.object
  }

  render() {
    const { auth, location } = this.props

    // handle initial loading of auth
    if (!isLoaded(auth)) {
      // TODO nicer loading animation
      return <LoadingPage />
    }

    // auth is empty (i.e. not logged in)
    if (isEmpty(auth)) {
      return (
        <Redirect
          to="/login"
          state={{ from: { pathname: location.pathname } }}
          noThrow
        />
      )
    }

    const { logbookVisible, preferencesVisible } = parseURL(location.pathname)

    return (
      <SchemaVersionErrorPage>
        <ActionsPage
          uid={auth.uid}
          displayName={auth.displayName}
          location={location}
          logbookVisible={logbookVisible}
          preferencesVisible={preferencesVisible}
        />
      </SchemaVersionErrorPage>
    )
  }
}

const ActionsHome = connect(({ firebase: { auth } }) => ({ auth }))(
  ActionsHomeInner
)
ActionsHome.displayName = 'ActionsHome'

export const Home = () => (
  <Suspense fallback={<LoadingPage />}>
    <Router primary={false}>
      <Login path="/login" />
      <Logout path="/logout" />
      <ActionsHome default />
    </Router>
  </Suspense>
)
